import { useEffect } from 'react';
import type { NextPage } from 'next';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';
import { DEFAULT_PATH } from 'services/DefaultPathsService';

const Home: NextPage = () => {
  const { user, error, isLoading } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (isLoading) return;

    if (error) {
      throw new Error('user-related error', error);
    }
    if (user) {
      router.push(DEFAULT_PATH.loggedUserLandingPath);
    }

    if (!user) {
      router.push(DEFAULT_PATH.apiLoginPath);
    }
  }, [user, error, isLoading, router]);

  return null;
};

export default Home;
